<template>
  <div class="container">
    <el-dialog title="发送客户清单" width="600px" v-model="dialogVisible">
      <div class="wrap">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column label="客户头像" width="70">
            <template #default="scope">
              <div class="action-wrap">
                <img class="head" :src="scope.row.FriendHeadImg" alt="" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="FriendNickName"
            label="客户昵称"
          ></el-table-column>
          <el-table-column
            prop="RobotNickName"
            label="助手名称"
          ></el-table-column>
        </el-table>
        
      </div>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
      <template #footer> </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { GetdestContact } from "@/helper/group";
import TableFoot from "@/components/TableFoot";
export default {
  components: {
    TableFoot,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const tableData = ref([]);
    const SID = ref("");
    const sourceData = reactive({
      data:{}
    })
    function initCop(sid,obj) {
      SID.value = sid;
      dialogVisible.value = true;
      sourceData.data =  obj
      searchAction();
    }
   
    //搜索
    function searchAction() {
      tableData.value = []
      let params = {
        PrivateSendID: SID.value,
        Conditions: sourceData.data.Conditions,
        ExcludeNames: sourceData.data.ExcludeNames,
        ConditionType: sourceData.data.ConditionType,
        PageNum: pageObj.PageIndex,
        PageSize: pageObj.PageSize, //分页
      };
      GetdestContact(params).then((res) => {
        tableData.value = res.List;
        pageObj.TotalRow = res.TotalCount
      });
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }

    return {
      initCop,
      dialogVisible,
      searchAction,
      tableData,
      SID,
      pageObj,
      changePage,
      changeSize
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap {
  max-height: 350px;
  width: 100%;
  overflow: scroll;
}
.action-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head {
  width: 40px;
  height: 40px;
}
</style>
<template>
  <div class="container-wrap">
    <div class="top-wrap">
      <div>根据微信规则,为避免引起封号，即使群发请遵守以下规则:</div>
      <div>1.延迟1-3分钟发送；</div>
      <div>
        2.内容不得包含微信禁止关键字(<span
          style="cursor: pointer"
          @click="showKeywordsAction"
          >显示</span
        >)
      </div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleFormRef"
      label-width="100px"
      class="ruleForm"
    >
      <el-form-item label="单聊名称" prop="name">
        <div>
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写名称"
            maxlength="24"
            show-word-limit
            :disabled="!isEdit"
          ></el-input>
          <p>名字只作为辨别多个条目之用，不会显示在群发内容中</p>
        </div>
      </el-form-item>
      <el-form-item label="发送时间" prop="time">
        <div>
          <el-date-picker
            v-model="ruleForm.time"
            type="datetime"
            placeholder="请选择时间"
            style="width: 100%"
            :disabledDate="isDisadbleAction"
            @change="changeTimeAction"
            :disabled="!isEdit"
          ></el-date-picker>
          <p>发送时间必须大于当前时间</p>
        </div>
      </el-form-item>

      <el-form-item label="发送条件" prop="fliter">
        <div>
          <div class="fliter-wrap" v-if="fliterArr.length > 0">
            <div class="wrap-left" v-if="fliterArr.length > 1">
              <div class="wrap-line"></div>
              <p @click="fliterLionAction">
                {{ fliterLion == 0 ? "并且" : "或者" }}
              </p>
            </div>

            <div class="wrap-right">
              <div class="cell" v-for="(item, index) in fliterArr" :key="index">
                <el-select
                  v-model="item.type"
                  placeholder="请选择"
                  @change="changeSelectAction"
                  :disabled="!isEdit"
                >
                  <el-option
                    v-for="item2 in fliterType"
                    :key="item2.value"
                    :label="item2.label"
                    :value="item2.value"
                    :disabled="item2.disabled"
                  ></el-option>
                </el-select>
                <div v-if="item.type == 0">
                  <el-button
                    type="primary"
                    class="cell-btn"
                    @click="addRobotAction(index)"
                    >选择助手</el-button
                  >
                  <p>
                    已选择（<span>{{ item.robots.length || 0 }}</span
                    >）个助手
                  </p>
                </div>
                <div v-if="item.type == 1">
                  <el-button
                    type="primary"
                    class="cell-btn"
                    @click="addTagsAction(index)"
                    >选择标签</el-button
                  >
                  <el-button
                    type="primary"
                    class="cell-btn-sync"
                    @click="goSync"
                    >去同步</el-button
                  >
                  <p style="margin-left:10px;width:173px">
                    已选择（<span>{{ item.tags.length || 0 }}</span
                    >）个标签
                  </p>
                </div>
                <div v-if="item.type == 2">
                  <el-button
                    type="primary"
                    class="cell-btn"
                    @click="addCustomerAction(index)"
                    >选择客户</el-button
                  >
                  <p>
                    已选择（<span>{{ item.customers.length || 0 }}</span
                    >）个客户
                  </p>
                </div>
                <div v-if="item.type == 3">
                  <el-date-picker
                    v-model="item.date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="changeDate"
                    :disabled="!isEdit"
                  ></el-date-picker>
                </div>
                <div v-if="item.type == 4" class="select-wrap">
                  <el-select v-model="item.status" placeholder="请选择" @change="changeStatus" :disabled="!isEdit">
                    <el-option
                      v-for="item1 in userGroupStatus"
                      :key="item1.value"
                      :label="item1.label"
                      :value="item1.value"
                    ></el-option>
                  </el-select>
                </div>

                <div class="cell-delete" @click="deleteAction(index)" v-if="isEdit">
                  <el-icon :size="20" :color="'red'">
                    <RemoveFilled />
                  </el-icon>
                </div>
              </div>
            </div>
          </div>
          <el-button
            type="primary"
            v-if="fliterArr.length < 5"
            @click="addFliterAction"
            >添加条件</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="按昵称排除">
        <div>
          <div class="search-title">
            <p>
              已添加(<span>{{ excludeUserList.length || 0 }}</span
              >)个用户
            </p>
            <el-button type="primary" @click="addUserAction">添加</el-button>
          </div>
          <div class="search-tips">
            预计发送<span>{{ simluteObj.TotalCount || 0 }}</span
            >位客户，排除<span>{{ simluteObj.ExcludeCount || 0 }}</span
            >位客户，实际发送<span
              style="text-decoration: underline; cursor: pointer"
              @click="totalUserAction"
              >{{ simluteObj.ActualCount || 0 }}</span
            >位客户，预计发送时间为<span>{{ simluteObj.TimeSpan || 0 }}秒</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="单聊内容" prop="content">
        <GroupMsgCop
          :materialArr="ruleForm.content"
          :isEdit='isEdit'
          @onChange="changeMsgContent"
          ref="groupMsgRef"
        />
      </el-form-item>

      <div class="foot-wrap">
        <p>*满足条件时，若客户被多名助手添加，则以最近添加的助手来发送</p>
        <div class="btn-wrap">
           <el-button  type="primary" @click="testSendAction" :disabled="timer?true:false" v-if="isEdit">
             <div  class="btn-center">
                <el-icon class="el-icon--left" ><Promotion /></el-icon>
                 {{timer?timerValue+'秒后可发送':'测试发送'}}
             </div>
          </el-button>

          <div>
            <el-button
            type="primary"
            size="medium"
            @click="submitForm('ruleForm')"
            >确 定</el-button
          >
          <el-button size="medium" @click="backAction">取 消</el-button>
          </div>
          
         
        </div>
      </div>
    </el-form>
    <GroupSelectCop ref="customerTestSelectRef" @onConfirm="customerTestConfirmAction"/>
    <GroupSelectCop ref="groupSelectRef" @onConfirm="userConfirmAction" />
    <GroupSelectCop ref="robotSelectRef" @onConfirm="robotConfirmAction" />
    <GroupSelectCop ref="customerSelectRef" @onConfirm="customerConfirmAction"/>
    <SingleTagCop ref="tagSelectRef" @onConfirm="tagConfirmAction" />
    <FliterUserCop ref="fliterUserRef" />
    <KeywordCop ref="keywordDialog" />
  </div>
</template>

<script>
import { reactive, ref, onMounted, unref ,onUnmounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import KeywordCop from "@/components/KeywordCop";
import GroupMsgCop from "@/components/GroupMsg/GroupMsgCop";
import GroupSelectCop from "@/components/GroupSelectCop";
import SingleTagCop from "@/components/SingleTagCop";
import FliterUserCop from "@/components/GroupMsg/FliterUserCop";
import { CircleCloseFilled, RemoveFilled,Promotion} from "@element-plus/icons";
import { ElMessageBox, ElMessage } from "element-plus";
import { PrivateCreateTest } from "@/helper/group";
import tools from "@/assets/js/tools";
import {
  GetPrivateSendDetail,
  CreatePrivateSend,
  UpdatePrivateSend,
  GetSimulateCount,
} from "@/helper/group";

export default {
  name: "CreateSingleSend",
  components: {
    GroupMsgCop,
    GroupSelectCop,
    SingleTagCop,
    FliterUserCop,
    CircleCloseFilled,
    RemoveFilled,
    KeywordCop,
    Promotion
  },
  setup() {
    let reClick= false;
    const SID = useRoute().query.SID;
    const isEdit = useRoute().query.isEdit==1?true:false;
  
    const IsCopy = useRoute().query.IsCopy;
    const router = useRouter();
    const ruleFormRef = ref(null);
    const fliterUserRef = ref(null);
    const keywordDialog = ref(null);
    const groupMsgRef = ref(null);
    const customerTestSelectRef = ref(null)

    const simluteObj = reactive({
      TotalCount: 0,
      ExcludeCount: 0,
      ActualCount: 0,
      TimeSpan: 0,
    });
    const ruleForm = reactive({
      name: "",
      time: "",
      content: [],
    });

    const rules = reactive({
      name: [
        { required: true, message: "请填写名称", trigger: "blur" }
      ],
      time: [{ required: true, message: "请填写时间", trigger: "change" }],
      fliter: [{validator: validateFliter,required: true,message: "请添加群发条件",trigger: "change",}],
      content: [
        {
          validator: validatePass,
          required: true,
          message: "请选择群发内容",
          trigger: "change",
        },
      ],
    });

    function validatePass(rule, value, callback) {
      if (!ruleForm.content || ruleForm.content.length <= 0) {
        callback(new Error("请选择群发内容"));
      } else {
        callback();
      }
    }

    //添加条件校验
    function validateFliter(rule, value, callback) {
      
      if (fliterArr.value&&fliterArr.value.length>0) {
        let flag = false
        fliterArr.value.forEach(item=>{
          
            if(item.robots&&item.robots.length<=0&&item.type==0){
              flag = true
            }
            if(item.tags&&item.tags.length<=0&&item.type==1&&item.taglion!=-1){
              flag = true
            }
            if(item.customers&&item.customers.length<=0&&item.type==2){
              flag = true
            }
            if(item.date&&item.date.length<=0&&item.type==3){
              flag = true
            }
            
        })
        if(flag){
           callback(new Error("请添加群发条件"));
        }else {
           callback();
        }
       
      } else {
        callback(new Error("请添加群发条件"));
      }
      
    }

    onMounted(() => {
      if (SID) {
        GetPrivateSendDetail({ PrivateSendID: SID }).then((res) => {
          ruleForm.time = IsCopy?"":res.krInfo.ExpectedSendTime;
          
          ruleForm.name = res.krInfo.Name;
          fliterLion.value = res.krInfo.ConditionType - 1;
          ruleForm.content = res.materialList;
          
          simluteObj.TotalCount = res.simulateCount.TotalCount;
          simluteObj.ExcludeCount = res.simulateCount.ExcludeCount;
          simluteObj.ActualCount = res.simulateCount.ActualCount;
          simluteObj.TimeSpan = res.simulateCount.TimeSpan;
          //条件需要  各种参数
          fliterArr.value = changeDataToFilter(
            res.krInfo.Conditions,
            res.krInfo.XJoin
          );
          //排除群需要id,
          excludeUserList.value = res.krInfo.ExcludeNames || [];
          groupMsgRef.value.initData(res.materialList,getConditions());
        });
      }
    });
    onUnmounted(()=>{
      stopTimer()
    })
    //展示关键字
    function showKeywordsAction() {
      keywordDialog.value.initCop();
    }

    //时间禁止范围
    function isDisadbleAction(time) {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    }
    //改变当前时间
    function changeTimeAction(time) {
      var startAt = new Date(time);
      if (startAt.getTime() < new Date().getTime()) {
        // ruleForm.time = tools.GMTToStr(new Date(), 2);
        ruleForm.time=new Date()
      } 
    }
    //总人数
    function totalUserAction() {
      fliterUserRef.value.initCop(IsCopy?'':SID, getConditions());
    }

    //群发内容变更
    function changeMsgContent(arr) {
      if (arr && arr.length > 0) ruleFormRef.value.clearValidate("content");
      ruleForm.content = arr;
    }

    //跳转企业标签
    function goSync(){
      router.push("/SyncGroupList");
    }
    /*******************条件组件*********************/
    const robotSelectRef = ref(null);
    const customerSelectRef = ref(null);
    const tagSelectRef = ref(null);
    //弹出弹框前的暂存下标
    const holdIndex = ref(0);
    //链接条件 0：并且 1：或者
    const fliterLion = ref(0);
    const fliterArr = ref([]);
    const fliterType = ref([
      { label: "按助手发送", value: 0, disabled: false },
      { label: "按标签发送", value: 1, disabled: false },
      { label: "按昵称发送", value: 2, disabled: false },
      { label: "按添加时间", value: 3, disabled: false },
      { label: "按用户当前群状态", value: 4, disabled: false },
    ]);
    //群状态
    const userGroupStatus = ref([
      { label: "未入群", value: 0 },
      { label: "已退群", value: 1 },
      { label: "已入群", value: 2 },
      { label: "当前不在群内(含未入群+已退群)", value: 3 },
    ]);

    //添加条件  条件需唯一
    function addFliterAction() {
      if(!isEdit){
        return
      }
      if (fliterArr.value.length < 5) {
        let tempArr = [0, 1, 2, 3, 4];
        for (let item of fliterArr.value) {
          let index = tempArr.indexOf(item.type);
          tempArr.splice(index, 1);
        }
        let tag = tempArr[0];
        fliterType.value[tag].disabled = true;
        fliterArr.value.push({
          type: tag,
          robots: [],
          tags: [],
          taglion: 0,
          customers: [],
          date: "",
          status: 0,
        });
        if(tag==4){
          refreshSimulate()
        }
      }
    }

    //变更
    function changeSelectAction() {
      let tempArr = [0, 1, 2, 3, 4];
      for (let item of fliterArr.value) {
        fliterType.value[item.type].disabled = true;
        let index = tempArr.indexOf(item.type);
        tempArr.splice(index, 1);
      }

      for (let item of tempArr) {
        fliterType.value[item].disabled = false;
      }
      refreshSimulate();
    }

    //删除
    function deleteAction(index) {
      if(!isEdit){
        return
      }
      let item = fliterArr.value[index];
      fliterType.value[item.type].disabled = false;
      fliterArr.value.splice(index, 1);
      refreshSimulate();
    }

    //连接条件变更
    function fliterLionAction() {
      if(!isEdit){
        return
      }
      fliterLion.value = fliterLion.value == 1 ? 0 : 1;
      refreshSimulate();
    }

    //添加机器人
    function addRobotAction(index) {
      holdIndex.value = index;
      robotSelectRef.value.initCop(1, fliterArr.value[index].robots,isEdit);
    }

    //机器人确认
    function robotConfirmAction(arr) {
      fliterArr.value[holdIndex.value].robots = arr;
      refreshSimulate();
    }

    //添加标签
    function addTagsAction(index) {
      if(!isEdit){
        return
      }
      holdIndex.value = index;
      tagSelectRef.value.initCop(
        fliterArr.value[index].taglion,
        fliterArr.value[index].tags
      );
    }

    //标签确认
    function tagConfirmAction(type, arr) {
      fliterArr.value[holdIndex.value].tags = arr;
      fliterArr.value[holdIndex.value].taglion = type;
      refreshSimulate();
    }

    //添加客户
    function addCustomerAction(index) {
      holdIndex.value = index;
      customerSelectRef.value.initCop(2, fliterArr.value[index].customers,isEdit);
    }

    //客户选择
    function customerConfirmAction(arr) {
      fliterArr.value[holdIndex.value].customers = arr;
      refreshSimulate();
    }

    //转化成本地数据格式
    function changeDataToFilter(obj, xjoin) {
      let result = [];
      if (obj.Robot && obj.Robot.length > 0) {
        let tempObj = {
          type: 0,
          robots: obj.Robot,
          tags: [],
          taglion: 0,
          customers: [],
          date: "",
          status: 0,
        };
        result.push(tempObj);
        fliterType.value[0].disabled = true;
      }
      if (obj.Tag && obj.Tag.length > 0) {
        let tempObj = {
          type: 1,
          tags: obj.Tag,
          taglion: xjoin,
          robots: [],
          customers: [],
          date: "",
          status: 0,
        };
        result.push(tempObj);
        fliterType.value[1].disabled = true;
      }
      if (obj.Member && obj.Member.length > 0) {
        let tempObj = {
          type: 2,
          customers: obj.Member,
          robots: [],
          tags: [],
          taglion: 0,
          date: "",
          status: 0,
        };
        result.push(tempObj);
        fliterType.value[2].disabled = true;
      }
      if (obj.CreateTime) {
        let tempObj = {
          type: 3,
          date: [obj.CreateTime.Start, obj.CreateTime.End],
          robots: [],
          tags: [],
          taglion: 0,
          customers: [],
          status: 0,
        };
        result.push(tempObj);
        fliterType.value[3].disabled = true;
      }
      if (obj.InGroupType||obj.InGroupType==0) {
        let tempObj = {
          type: 4,
          status: obj.InGroupType + 1,
          robots: [],
          tags: [],
          taglion: 0,
          customers: [],
          date: "",
        };
        result.push(tempObj);
        fliterType.value[4].disabled = true;
      }
      return result;
    }

    //条件 变更
    function refreshSimulate() {
      ruleFormRef.value.validate('fliter')
      let obj = getConditions()
      groupMsgRef.value.resetRobot(obj)
      // ruleFormRef.value.resetFields();
      
      if(obj.Conditions.length<=0&&obj.ExcludeNames.length<=0){
        simluteObj.TotalCount = 0;
        simluteObj.ExcludeCount = 0;
        simluteObj.ActualCount = 0;
        simluteObj.TimeSpan = 0;
        return
      }
      GetSimulateCount(obj).then((res) => {
        simluteObj.TotalCount = res.TotalCount;
        simluteObj.ExcludeCount = res.ExcludeCount;
        simluteObj.ActualCount = res.ActualCount;
        simluteObj.TimeSpan = res.TimeSpan;
      });
    }

    /*******************群选组件*********************/
    const groupSelectRef = ref(null);
    //排除用户
    const excludeUserList = ref([]);

    //添加用户
    function addUserAction() {
      groupSelectRef.value.initCop(2, excludeUserList.value,isEdit);
    }

    //用户确认
    function userConfirmAction(arr) {
      excludeUserList.value = arr;
      refreshSimulate();
    }

    //更改时间
    function changeDate(){
      refreshSimulate();
    }
    //更改选择条件
    function changeStatus(){
      refreshSimulate();
    }
    //测试发送
    function testSendAction(){
      customerTestSelectRef.value.initCop(2,[],isEdit);
       
    }
    //测试客户确认
    function customerTestConfirmAction(arr){
      let fliterMsgObj = fliterMsgContent();
      let params = {
        TextContent:fliterMsgObj.textArr,
        MaterialIDs:fliterMsgObj.noTextArr,
        CustomerIDs:arr&&arr.length>0?arr.map((item) => item.MemberID):[],
        InviteRule:fliterMsgObj.InviteArr
      }
      ElMessageBox.confirm('确定后系统将在1分左右将内容发送至选中的群聊,您确定要发送吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
      }).then(() => {
        PrivateCreateTest(params).then(res=>{
          ElMessage.success('发送成功')
          startTimer()
        })
      }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消'
            });
      });
    }

    const timer= ref(null)
    const timerValue= ref(0)
    function startTimer(){
      stopTimer() 
      let count = 0
      timer.value = setInterval(() => {
        count++
        if(count>=60){
          stopTimer()
        }
        timerValue.value = 60 - count
      },1000);
    }

    function stopTimer(){
      if(timer.value){
        clearInterval(timer.value);
        timer.value = null
        timerValue.value = 0
      } 
    }
    /*************提交*****************/
    //转化成提交格式条件
    function getConditions() {
      let conditions = [];
      let excludeNames = [];
      excludeNames = excludeUserList.value.map((item) => item.MemberID);
      fliterArr.value.forEach((item) => {
        var obj = { Type: item.type + 1 };
        if (item.type == 0) {
          obj.Value = item.robots.map((v) => v.RobotID);
          conditions.push(obj);
        } else if (item.type == 1) {
          obj.Value = item.tags.map((v) => v.ID);
          obj.XJoin = item.taglion;
          conditions.push(obj);
        } else if (item.type == 2) {
          obj.Value = item.customers.map((v) => v.MemberID);
          conditions.push(obj);
        } else if (item.type == 3) {
          let arr = []
          if(item.date&&item.date.length>0){
            arr.push(tools.GMTToStr(item.date[0]))
            arr.push(tools.GMTToStr(item.date[1]))
          }
          obj.Value = arr;
          conditions.push(obj);
        } else if (item.type == 4) {
          obj.Value = [item.status - 1];
          conditions.push(obj);
        }
      });
      return {
        Conditions: conditions,
        ExcludeNames: excludeNames,
        ConditionType: fliterLion.value + 1,
      };
    }
    function fliterMsgContent() {
      let textArr = [];
      let noTextArr = [];
      let InviteArr = [];
      ruleForm.content.forEach((item) => {
        if (item.Type == 2001) {
          textArr.push(item.MsgContent);
        } else if (item.Type == 123000) {
          if (item.source) {
            item.source.forEach((ele) => {
              let prop = {
                RobotID: ele.RobotID,
                GroupID: ele.groupItem?ele.groupItem.GroupID:0,
              };
              InviteArr.push(prop);
            });
          } else {
            item.InviteRule.forEach((ele) => {
              let prop = {
                RobotID: ele.RobotID,
                GroupID: ele.GroupID,
              };
              InviteArr.push(prop);
            });
          }
        } else {
          noTextArr.push(item.ID);
        }
      });
      return { textArr: textArr, noTextArr: noTextArr, InviteArr: InviteArr };
    }

    //返回
    function backAction() {
      router.go(-1);
    }
    //确定表单
    const submitForm = async () => {
      if(reClick) return
      reClick = true
      
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
         if(!isEdit){
          router.go(-1);
          return
        }
        let fliterMsgObj = fliterMsgContent();
        let obj = getConditions();
        let params = {
          Name: ruleForm.name,
          Conditions: obj.Conditions,
          ExcludeNames: obj.ExcludeNames,
          TextContent: fliterMsgObj.textArr,
          MaterialIDs: fliterMsgObj.noTextArr,
          ConditionType: obj.ConditionType,
          ExpectedSendTime: tools.GMTToStr(ruleForm.time, 2),
          InviteRule: fliterMsgObj.InviteArr,
        };
        if (SID && !IsCopy) {
          params.ID = SID;
          UpdatePrivateSend(params).then((res) => {
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            router.go(-1);
            reClick = false
          })
        } else {
          CreatePrivateSend(params).then((res) => {
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            router.go(-1);
            reClick = false
          });
        }
      } catch (error) {
        reClick = false
        console.log(error);
      }
    };
    return {
      rules,
      ruleFormRef,
      ruleForm,
      groupSelectRef,
      userConfirmAction,
      excludeUserList,
      addUserAction,
      fliterArr,
      addFliterAction,
      fliterType,
      fliterLion,
      fliterLionAction,
      addRobotAction,
      robotConfirmAction,
      robotSelectRef,
      holdIndex,
      addTagsAction,
      tagSelectRef,
      tagConfirmAction,
      isDisadbleAction,
      changeTimeAction,
      totalUserAction,
      fliterUserRef,
      userGroupStatus,
      addCustomerAction,
      customerSelectRef,
      customerConfirmAction,
      changeSelectAction,
      deleteAction,
      submitForm,
      backAction,
      simluteObj,
      changeMsgContent,
      validatePass,
      changeDataToFilter,
      showKeywordsAction,
      keywordDialog,
      groupMsgRef,
      changeDate,
      changeStatus,
      groupMsgRef,
      validateFliter,
      isEdit,
      goSync,
      testSendAction,
      customerTestSelectRef,
      customerTestConfirmAction,
      timer,
      timerValue
    };
  },
};
</script>
<style lang='scss' scoped>
.container-wrap {
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}
.top-wrap {
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;

  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 24px;
  color: $color-text;
  span {
    color: $color-common;
  }
}

.ruleForm {
  width: 400px;
  margin: 30px 20px;
  p {
    font-size: 12px;
    color: #999;
    margin-right: 10px;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}

.foot-wrap {
  width: 600px;
  margin-top: 10px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  p {
    color: red;
  }
  .btn-wrap {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.search-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    font-size: 14px;
    color: #000;
  }

  span {
    color: $color-common;
  }
}

.search-tips {
  display: flex;
  flex-direction: row;
  width: 700px;
  height: 40px;
  align-items: center;
  font-size: 12px;
  background-color: #f2f2f2;
  border: 1px dashed #ccc;
  margin-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  span {
    color: $color-common;
  }
}

.fliter-wrap {
  width: 700px;
  display: flex;
  flex-direction: row;

  .wrap-left {
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .wrap-line {
      height: 100%;
      width: 1px;
      border-right: 1px dashed $color-common;
    }
    p {
      position: absolute;
      width: 100%;
      height: 30px;
      border: 1px dashed $color-common;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
      margin-right: 0;
    }
  }

  .wrap-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .cell {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
      }

      .cell-delete {
        width: 20px;
        height: 20px;
        margin: 0 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .cell-btn {
        margin-right: 10px;
      }
      .cell-btn-sync{
        margin-left:0;
      }
      .el-input {
        width: 120px;
        margin-right: 10px;
        margin-left: 0px;
      }
      .select-wrap .el-select {
        margin-left: 0px;
        margin-right: 177px;
      }
      p {
        width: 250px;
        color: #000;
        font-size: 14px;
        span {
          color: $color-common;
        }
      }
    }
  }
}
.btn-center{
  display: flex;
  align-items: center;
}
</style>